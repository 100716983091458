<template>
  <v-row justify="center">
    <v-dialog :value="true" persistent max-width="800px" scrollable transition="dialog-transition" @input="$emit('close-dialog')">
      <v-card>
        <v-card-title>
          <v-list-item class="pa-0">
            <v-list-item-content>
              <v-list-item-title v-text="$t('organization.invitePeople')" />
            </v-list-item-content>
            <v-spacer />
            <v-list-item-action>
              <v-btn icon color="black" @click="$emit('close-dialog')">
                <v-icon>close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card-title>

        <v-card-text class="pt-1">
          <v-form>
            <v-select
              v-model="form.role"
              class="mb-4"
              :items="roles"
              :label="$t('common.role')"
            />
            <v-combobox
              :value="form.emails"
              :label="$t('common.emails')"
              multiple
              deletable-chips
              append-icon=""
              :delimiters="[',',' ']"
              small-chips
              validate-on-blur
              outlined
              :error-messages="getFieldErrors('emails')"
              @change="form.emails = $event.reduce((acc, curr) => [...acc, ...curr.trim().toLowerCase().split(/[, ]+/)], [])"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="px-6 pb-5 pt-0">
          <v-spacer />
          <v-btn color="primary" small depressed width="120" :disabled="disabledInviteBtn" @click="invite" v-text=" $t('common.invite')" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { email } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'AddStaffDialog',
  mixins: [formUtils],
  props: {
    organizationId: { type: String, required: true },
  },
  data() {
    return {
      roles: [
        { text: this.$t('user.roles.admin'), value: 'admin' },
      ],
      form: {
        role: 'admin',
        emails: [],
      },
    }
  },
  computed: {
    disabledInviteBtn({ form }) {
      return form.emails.length === 0 || this.$v.form.$invalid
    },
  },
  validations() {
    return {
      form: {
        emails: { $each: { email } },
      },
    }
  },
  methods: {
    invite() {
      const { form, organizationId } = this
      if (!this.isFormValid()) return
      this.runAsync(async () => {
        await Promise.all(form.emails.map(email => this.$store.dispatch('organization/addStaffer', { organizationId, email, role: form.role })))
        this.$emit('close-dialog')
      })
    },
  },
}
</script>
